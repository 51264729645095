import React, { FC, lazy } from 'react';
import { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import ModalRoute from 'context/ModalRouter';

type Props = {};

const DeleteReport = lazy(
  () => import('app/modules/reports/modules/deleteReportModal'),
);
const ShareReport = lazy(
  () => import('app/modules/reports/modules/shareReportModal'),
);
const DuplicateReport = lazy(
  () => import('app/modules/reports/modules/duplicateReportModal'),
);

const ReportsModals: FC<Props> = (props) => {
  return (
    <>
      <UserPermissionGate
        scopes={[
          UserPermission.POST_DELETE,
          // TODO: permission
        ]}
      >
        <ModalRoute path={modalRoutes.DELETE_REPORT} component={DeleteReport} />
      </UserPermissionGate>
      <UserPermissionGate
        scopes={[
          UserPermission.POST_DELETE,
          // TODO: permission
        ]}
      >
        <ModalRoute path={modalRoutes.SHARE_REPORT} component={ShareReport} />
      </UserPermissionGate>
      <UserPermissionGate
        scopes={[
          UserPermission.POST_DELETE,
          // TODO: permission
        ]}
      >
        <ModalRoute
          path={modalRoutes.DUPLICATE_REPORT}
          component={DuplicateReport}
        />
      </UserPermissionGate>
    </>
  );
};

export default ReportsModals;
