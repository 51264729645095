import React, { FC, useState } from 'react';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';
import {
  JsonParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ApolloProvider } from '@apollo/client';
import ReportFailedToBuildMessage from 'app/modules/public/modules/pdfReports/components/ReportFailedToBuildMessage';
import Container from 'components/insights/Container';
import PagePerformancePdfExportMetricChartsCards from 'app/modules/public/modules/pdfReports/modules/insightsPagePerformance/components/PagePerformancePdfExportMetricChartsCards';
import dayjs from 'dayjs';
import { Page } from 'app/modules/insights/types/Page';
import PdfExportCoverPage from 'app/modules/public/modules/pdfReports/components/PdfExportCoverPage';
import { PAGE_PERFORMANCE_CURRENT_SELECTION_PDF_METRICS } from 'app/modules/public/modules/pdfReports/modules/insightsPagePerformance/modules/insightsPagePerformanceOverview/constants';
import ReportHeader from 'app/modules/public/modules/pdfReports/modules/insightsPostsPerformance/components/reportPostsPerformance/ReportHeader';

type Props = {};

const InsightsPagePerformanceCurrentSelectionPDFReportRoute: FC<Props> = (
  props,
) => {
  const [query] = useQueryParams({
    token: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    page: JsonParam,
    types: withDefault(NumericArrayParam, []),
  });

  const [client] = useState(() =>
    createInsightsApolloClient(query.token ?? ''),
  );

  if (
    query.token &&
    query.startDate &&
    query.endDate &&
    query.page &&
    query.types
  ) {
    const formattedQueryValues = {
      startDate: dayjs(query.startDate),
      endDate: dayjs(query.endDate),
      page: query.page as Page,
      types: query.types.flatMap((type) => (type !== null ? [type] : [])),
    };

    return (
      <ApolloProvider client={client}>
        <Container>
          <div style={{ breakInside: 'avoid' }} className="tw-min-h-[1137px]">
            <PdfExportCoverPage
              page={formattedQueryValues.page}
              startDate={formattedQueryValues.startDate}
              endDate={formattedQueryValues.endDate}
            />
          </div>
          <div style={{ breakInside: 'avoid' }}>
            <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
              <span className="tw-text-xl tw-font-bold">Page performance</span>
              <ReportHeader {...formattedQueryValues} />
            </div>
            <PagePerformancePdfExportMetricChartsCards
              page={formattedQueryValues.page}
              selectedMetrics={
                PAGE_PERFORMANCE_CURRENT_SELECTION_PDF_METRICS[
                  formattedQueryValues.page._type
                ] ?? []
              }
            />
          </div>
        </Container>
      </ApolloProvider>
    );
  }

  return <ReportFailedToBuildMessage />;
};

export default InsightsPagePerformanceCurrentSelectionPDFReportRoute;
