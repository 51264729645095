import { Action, ActionContext } from 'actions';
import { deleteReport } from './deleteReport';
import { shareReport } from './shareReport';
import { duplicateReport } from './duplicateReport';
import { editReport } from './editReport';
import { viewReport } from './viewReport';
import { regenerateReport } from './regenerateReport';

export const reportActions = (context: ActionContext) =>
  ({
    delete: deleteReport(context),
    share: shareReport(context),
    duplicate: duplicateReport(context),
    edit: editReport(context),
    view: viewReport(context),
    regenerate: regenerateReport(context),
  } satisfies Record<string, Action>);
