import React, { FC, useState } from 'react';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';
import { ApolloProvider } from '@apollo/client';
import { StringParam, useQueryParams } from 'use-query-params';
import ReportFailedToBuildMessage from 'app/modules/public/modules/pdfReports/components/ReportFailedToBuildMessage';
import ReportApi from 'api/report';
import { queryKey } from 'constants/queryKey';
import { useQuery } from 'react-query';
import PdfExportCoverPage from '../../components/PdfExportCoverPage';
import Logger from 'utils/logger';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Container from 'components/insights/Container';
import ReportHeader from '../insightsPostsPerformance/components/reportPostsPerformance/ReportHeader';
import PageTypeUtils from 'app/utils/pageType';
import PagePerformancePdfExportMetricChartsCards from '../insightsPagePerformance/components/PagePerformancePdfExportMetricChartsCards';
import PageStatistics from 'app/modules/insights/modules/postsPerformance/components/PageStatistics';
import { getMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import PostsByMetricList from 'app/modules/insights/modules/postsPerformance/components/PostsByMetricList';
import { ALL_PAGES_PAGINATION } from 'app/modules/insights/constants/pagination';
import Skeleton from 'react-loading-skeleton';

type Props = {};

const CustomReportRoute: FC<Props> = (props) => {
  const { id } = useParams();
  let [params] = useQueryParams({
    token: StringParam,
  });
  const token =
    params.token ??
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoxLCJ3c2lkIjoiMWE2NDdhM2QxM2NmNDY0ZTlkY2QyYTY1ZWU5MTFjOWM2NzNhZjVmNDg5NjE3Ni4zNDQzODIyMCJ9LCJhY2NvdW50Ijp7ImlkIjoyfSwiYXBwIjoiNmQ4OTZjNGZlNzVkMGU0MWEwOGYwMmI2MDgwNGU0MjgiLCJpYXQiOjE3MzE5MTczMDAsImV4cCI6MTczNDUwOTMwMH0.plwavgfVuqTm2Z2nAwpKuWZjOQoVY5lIHp6WMxl0C1I';

  const [client] = useState(() => createInsightsApolloClient(token ?? ''));

  const { isLoading, data } = useQuery(
    queryKey.reportDetail(Number(id)),
    () => {
      return ReportApi.detail(Number(id), token);
    },
    {
      onSuccess: (data) => {},
    },
  );

  if (isLoading) {
    return <Skeleton width="100%" height={'200px'} />;
  }

  if (data && token) {
    return (
      <ApolloProvider client={client}>
        <Container>
          <div style={{ breakInside: 'avoid' }} className="tw-min-h-[1137px]">
            <PdfExportCoverPage
              title={data.name}
              startDate={dayjs(data.date_from)}
              endDate={dayjs(data.date_to)}
            />
          </div>
          {data.profiles?.map((profile) => {
            const page = {
              picture: '',
              id: profile.profile_id.toString(),
              _id: profile.profile_id.toString(),
              _type: PageTypeUtils.getNumberFromName(profile.profile_type),
              name: 'TODO: GET name',
              username: 'TODO: GET username ???',
              fans: null,
              userPageConfiguration: null,
            };
            return profile.sections.map((section) => {
              const metrics = Object.values(
                getMetricsForPageType(page._type),
              ).filter((metric) =>
                (section.metrics ?? []).includes(metric.key),
              );
              Logger.log('section.metrics', section.metrics);
              return {
                summary_cards: (
                  <div style={{ breakInside: 'avoid' }} key={section.id}>
                    <ReportHeader
                      name={data.name}
                      page={page}
                      startDate={dayjs(data.date_from)}
                      endDate={dayjs(data.date_to)}
                    />
                    <PageStatistics
                      page={page}
                      startDate={dayjs(data.date_from)}
                      endDate={dayjs(data.date_to)}
                      isExpandingDisabled
                      columns={2}
                    />
                  </div>
                ),
                page_performance: (
                  <div style={{ breakInside: 'avoid' }} key={section.id}>
                    <ReportHeader
                      name={data.name}
                      page={page}
                      startDate={dayjs(data.date_from)}
                      endDate={dayjs(data.date_to)}
                    />
                    <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                      <span className="tw-text-xl tw-font-bold">
                        Page performance
                      </span>
                    </div>
                    <PagePerformancePdfExportMetricChartsCards
                      page={page}
                      selectedMetrics={section.metrics}
                    />
                  </div>
                ),
                posts_performance: (
                  <div style={{ breakInside: 'avoid' }} key={section.id}>
                    <ReportHeader
                      name={data.name}
                      page={page}
                      startDate={dayjs(data.date_from)}
                      endDate={dayjs(data.date_to)}
                    />
                    {metrics.map((metric) => (
                      <PostsByMetricList
                        key={metric.key}
                        page={page}
                        types={[]}
                        metric={metric}
                        startDate={dayjs(data.date_from)}
                        endDate={dayjs(data.date_to)}
                        paginateBy={ALL_PAGES_PAGINATION}
                        postCollapsedLayoutVariant="rows"
                      />
                    ))}
                  </div>
                ),
              }[section.type];
            });
          })}
        </Container>
      </ApolloProvider>
    );
  }

  return <ReportFailedToBuildMessage />;
};

export default CustomReportRoute;
