import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import DateUtils, { formatDate } from 'app/utils/date';
import { Dayjs } from 'dayjs';
import { BrandLogo, SocialMediaIcon } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import PageTypeUtils from 'app/utils/pageType';
type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  className?: string;
  name?: string;
};

const ReportHeader: FC<Props> = ({
  startDate,
  endDate,
  page,
  name,
  className,
}) => {
  return (
    <div className={className}>
      <div className="tw-flex tw-flex-1 tw-justify-between tw-border-b tw-border-grayscale-20 tw-pb-[53px]">
        <div className="tw-flex tw-items-center tw-gap-4">
          <BrandLogo size={72} src={page.picture ?? ''} />
          <div className="tw-text-md">
            <span>{page.name}</span>
            <div className="tw-flex tw-items-center tw-gap-3">
              <SocialMediaIcon
                size={24}
                variant="plain"
                type={getSocialMediaTypeForBrandLogo(page._type)!}
              />
              <span>{PageTypeUtils.getLabel(page._type)}</span>
            </div>
          </div>
        </div>
        <div className="tw- tw-flex tw-flex-col tw-items-center">
          {name && <span>{name}</span>}
          <span>{`${formatDate(
            DateUtils.toDateString(startDate),
          )} - ${formatDate(DateUtils.toDateString(endDate))}`}</span>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
