import { faShareFromSquare } from '@fortawesome/pro-regular-svg-icons/faShareFromSquare';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';

export const shareReport = (context: ActionContext) =>
  createAction(
    {
      id: 'share-report',
      label: i18n.t('share-report'),
      icon: <Icon icon={faShareFromSquare} />,
      scopes: UserPermission.POST_SHARE_PREVIEW, // TODO: ...
      perform: ({ id }: { id: number }) => {
        context.modalHistory.push({
          hash: modalRoutes.SHARE_REPORT,
          search: `?id=${id}`,
        });
      },
    },
    context,
  );
