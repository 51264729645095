import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { UserPermission } from 'constants/userPermission';

export const editReport = (context: ActionContext) =>
  createAction(
    {
      id: 'edit-report',
      label: i18n.t('edit'),
      icon: <Icon icon={faEdit} />,
      scopes: UserPermission.POST_SHARE_PREVIEW, // TODO: ...
      perform: ({ id }: { id: number }) => {
        context.navigate(`/reports/${id}/edit`);
      },
    },
    context,
  );
