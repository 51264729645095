import { ActionContext, appActions } from 'actions';
import { useModalHistory } from 'context/ModalRouter';
import { useAccount } from 'modules/account/accountSelector';
import { useApp } from 'modules/app/appSelector';
import { useUser } from 'modules/user/userSelector';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function useActions(source: ActionContext['source']) {
  const modalHistory = useModalHistory();
  const navigate = useNavigate();
  const appStore = useApp();
  const account = useAccount();
  const user = useUser();

  return useMemo(
    () =>
      appActions({ modalHistory, source, appStore, account, user, navigate }),
    [modalHistory, source, appStore, account, user, navigate],
  );
}
