import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';
import { Outlet } from 'react-router';
import ReportsModals from 'components/modals/ReportsModals';

const NewReport = React.lazy(
  () => import('app/modules/reports/modules/pageNewReport'),
);
const EditReport = React.lazy(
  () => import('app/modules/reports/modules/pageEditReport'),
);
const MyReports = React.lazy(
  () => import('app/modules/reports/modules/pageMyReports'),
);

export const reportsRouter: RouteObject = {
  path: routeConfig.reports.path,
  element: (
    <Suspense
      fallback={
        <div className="tw-h-full tw-p-4">
          <Skeleton width="100%" height="100%" />
        </div>
      }
    >
      <Outlet />
      <ReportsModals />
    </Suspense>
  ),
  children: [
    {
      path: routeConfig.reports.routes.new.path,
      element: <NewReport />,
    },
    {
      path: routeConfig.reports.routes.edit.path,
      element: <EditReport />,
    },
    {
      path: routeConfig.reports.routes.my.path,
      element: <MyReports />,
    },
  ],
};
